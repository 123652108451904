import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import client1 from "../../../Assets/Clients/abudhabi-culture-tourism.png"
import client2 from "../../../Assets/Clients/abudhabi-police.png"
import client3 from "../../../Assets/Clients/abudhabi-sewage.png"
import client4 from "../../../Assets/Clients/agitha.png"
import client5 from "../../../Assets/Clients/australia-post.png"
import client6 from "../../../Assets/Clients/become.png"
import client7 from "../../../Assets/Clients/broccoli.png"
import client8 from "../../../Assets/Clients/canon.png"
import client9 from "../../../Assets/Clients/channel.png"
import client10 from "../../../Assets/Clients/dahua.png"
import client11 from "../../../Assets/Clients/emirates-nbd.png"
// import client12 from "../../../Assets/Clients/emirates.png"
import client13 from "../../../Assets/Clients/french-embassy.png"
import client14 from "../../../Assets/Clients/huawei.png"
import client15 from "../../../Assets/Clients/marhaba.png"
import client16 from "../../../Assets/Clients/mok.png"
// import client17 from "../../../Assets/Clients/mok.png"
import client18 from "../../../Assets/Clients/neom.png"
import client19 from "../../../Assets/Clients/pmi.png"
import client20 from "../../../Assets/Clients/reyl.png"
import client21 from "../../../Assets/Clients/rolex.png"
import client22 from "../../../Assets/Clients/rpm.png"
import client23 from "../../../Assets/Clients/saudi-aramco.png"
import client24 from "../../../Assets/Clients/shazam.png"
import client25 from "../../../Assets/Clients/siemens.png"
import client26 from "../../../Assets/Clients/snapchat.png"
import client27 from "../../../Assets/Clients/toshiba.png"
import client28 from "../../../Assets/Clients/yamaha.png"

const clients = [
  { img: client1, },
  { img: client2, },
  { img: client3, },
  { img: client4, },
  { img: client5, },
  { img: client6, },
  { img: client7, },
  { img: client8, },
  { img: client9, },
  { img: client10, },
  { img: client11, },
//   { img: client12, },
  { img: client13, },
  { img: client14, },
  { img: client15, },
  { img: client16, },
  // { img: client17 },
  { img: client18 },
  { img: client19 },
  { img: client21 },
  { img: client22 },
  { img: client23 },
  { img: client24 },
  { img: client25 },
  { img: client26 },
  { img: client27 },
  { img: client28 },
];


const serviceTypes = [
    {
        id: 1,
        title: " 3D Explainer Videos",
        description: "Of course, this is where 3D animation production shines brightest. We craft immersive, visually captivating animations that transform abstract concepts into dynamic, lifelike experiences. Whether it’s showcasing a product or illustrating a complex process, we’ll make it look stunning with our 3D animation video production.",
    },
    {
        id: 2,
        title: "Storytelling Testimonial Videos",
        description: "Customer stories get a modern twist with 3D animation services. Picture realistic characters, beautifully rendered settings, and visual effects that make their journey come alive. It’s storytelling at its most vibrant, perfect for showcasing how your brand impacts lives.",
    },
    {
        id: 3,
        title: "Educational Videos",
        description: "Learning is way more engaging when it feels like a journey. With 3D animation services, we craft visuals that bring your lessons to life, whether it's explaining complex ideas or guiding through processes.",
    },
    {
        id: 4,
        title: "Trade Show Videos",
        description: "3D product animation services are showstoppers. From spinning product models to immersive brand stories, these videos grab attention on the big screens and captivate your audience. With expertise in 3D animation and cutting-edge production techniques, we ensure your content leaves a lasting impression.",
    },
    {
        id: 5,
        title: "Live Action Videos",
        description: "Blend reality with imagination. By adding 3D animation to live footage, we create hybrid videos that feel innovative and exciting-perfect for grabbing your audience’s attention. Working with the best 3D animation studio,we’ll elevate your live-action footage with stunning visual effects.",
    },
    {
        id: 6,
        title: "Social Video Ads",
        description: "In a feed full of flat visuals, 3D animation leaps off the screen. Bold, vibrant, and endlessly scroll-stopping, these videos make your brand impossible to ignore. 3D video agency expertise makes all the difference in creating that extra layer of engagement.",
    },
    {
        id: 7,
        title: "Video Case Studies",
        description: "Data gets a glow-up with 3D product animation. We’ll build sleek visualizations and scenarios that let your success stories shine, making even the most technical details feel accessible and impressive. Our 3D animation house makes sure your results are presented in the best way possible.",
    },
    // {
    //     id: 8,
    //     title: "Video Courses",
    //     description: "Want to keep learners hooked? 3D animation interior design elements and dynamic visuals add depth and movement to your lessons, making educational content as visually rich as it is informative. At our 3D animation design studio, we know how to keep your audience engaged.",
    // },
    {
        id: 9,
        title: "Brand Videos",
        description: "Your brand deserves to stand out, and 3D animation does just that. From lifelike environments to unique visual effects, we’ll craft a story that’s as bold and unique as your brand. As a 3D animation company with top-notch design, we ensure your brand video makes a lasting impact.",
    },
    {
        id: 10,
        title: "Video Retainer",
        description: "Need fresh content on the regular? With our 3D animation services company, we’ll keep your visuals innovative, dynamic, and always ahead of the curve. Our 3D video company will ensure you always have fresh content that resonates with your audience.",
    } 
];

export default function Services({size}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % service.serviceTypes.length);
    //     }, 2000); // Change every 2 seconds
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    const textVariants = {
        initial: {
            y: 200,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
    };
    
    const childVariants = {
        initial: { y: 100, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
    }; 

    const settings = {
        // customPaging: function(i) {
        //   return (
        //     <a>
        //       <img 
        //         src={clientImages[i]} 
        //         alt={`Client ${i + 1}`} 
        //         style={{ width: "20px", height: "20px", objectFit: "cover" }} 
        //     />
        //     </a>
        //   );
        // },
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
      };

      const settings1 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false
            }
            }
        ]
      };

      useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 6,
            autoScroll: {
                speed: 1,
            },
            pagination: false,
            arrows: false,
            breakpoints: {
                1024: {
                  perPage: 4,
                },
                600: {
                  perPage: 3,
                },
                480: {
                    perPage: 2,
                },
              },
        });

        splide.mount({ AutoScroll });
    }, []);

    return (
        <section id="services" className="services">
            <div className="overlay"></div>
            <div className="services-content">
                <motion.div className="top container1" variants = {textVariants} initial="initial" whileInView="animate">
                    {/* <motion.div className="header-container"> */}
                        <motion.h1 variants={childVariants} className="heading">3D Animation provides
                            <motion.div variants={childVariants} className="service-types">
                            <Slider {...settings}>
                                {serviceTypes.map((ele) => {
                                    return (
                                        <div className="types" key={ele.id}>
                                            <h1>{ele.title}</h1>
                                        </div>
                                    )
                                })}
                            </Slider> 
                            <motion.p variants={childVariants}>that visually convey engaging narratives.</motion.p></motion.div> 
                        </motion.h1>
                    {/* </motion.div> */}
                    <motion.p variants={childVariants}>{serviceTypes[currentIndex].description}</motion.p>
                    <motion.a variants={childVariants} href="#about-contact"><button className="btn">
                        Book A free Consultation
                    </button></motion.a>
                </motion.div>
                <div className="bottom-overlay"></div>

                <div className="splide">
                    <div className="splide__track">
                        <ul className="splide__list">
                        {clients.map((ele, index) => (
                            <li className="splide__slide" key={index}>
                            <img src={ele.img} alt={`Client ${index + 1}`} className="slider-image" />
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}