import React, { useRef } from 'react'
import ReactIframe from 'react-iframe'
import {motion, useScroll, useTransform} from "framer-motion"
import "./AboutUs.css"

function About({ size }) {
  const textVariants = {
    initial: {
        y: 100,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: "easeOut",
            staggerChildren: 0.3, // Stagger the children by 0.3s
        },
    },
  };

  const childVariants = {
      initial: { y: 50, opacity: 0 },
      animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };
    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target: ref,
        start: ["start start", "end start"]
    })

  const yBg = useTransform(scrollYProgress, [0, 1], [-500, 500])
  return (
        <section id="about-contact" className='aboutHome container1' ref={ref} >
            <motion.div className='flexSB' style={{yBg}}>
                <div className='left'>
                    <motion.div 
                        initial="initial"
                        whileInView="animate"
                        transition={{
                          duration: 1,
                          ease: "easeOut",
                          delay: 0.2
                        }}
                        exit="exit"
                        viewport={{ once: false, amount: 0.5 }}
                        variants={textVariants}
                        className="aboutHeading">
                        <motion.h1 variants={childVariants}>WHO ARE WE?</motion.h1>
                        {/* <motion.h1>3D Animation</motion.h1> */}
                        <motion.h3 variants={childVariants}>{"Bringing Your Ideas to Life in 3D".toUpperCase()}</motion.h3>
                        <motion.h2 variants={childVariants}>We turn your stories into awesome 3D Animations that feel real and unforgettable.</motion.h2>
                        <motion.p variants={childVariants}>Ever wondered if you could turn your ideas into something you can almost reach out and touch? That’s the power of 3D animation. At Rabbit And Carrot, we don’t just create visuals; we build experiences. From lifelike characters to jaw-dropping simulations, we’ve been shaping stories in 3D since 2008-crafting work that speaks volumes without saying a word.<br/><br/>
                        Let’s take your imagination off the page and into a world that wows.</motion.p>
                    </motion.div>
                    {/* <div className='items'>
                            <h2>Creative Mastery</h2>
                            <h2>Strategic Impact</h2>
                            <h2>Innovative Solutions</h2>
                    </div> */}
                </div>
                <div className="right">
                        <ReactIframe
                            aria-label='Get A Quote' 
                            frameborder="0"
                            style={{ padding: '20px', border: 'none' }}
                            src='https://forms.zohopublic.com/virtualoffice10491/form/GetAFreeConsultation/formperma/3EW3lt-btg2aSOVuGldM_HXocdXKAnqtBSje-43zVJ0' 
                            width="100%" 
                            height="610px"
                        />
                </div>
                </motion.div>
        </section>
  )
}

export default About