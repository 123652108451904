import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from 'react-google-recaptcha';

import './PopupForm.css';
// import logo from "../../../Assets/Logo/RNC-Logo-black.png"
import closeButton from "../../../Assets/Common/close.webp"
import logo from "../../../Assets/Logo/logo5.webp"

// import fliplogo from "../../../Assets/Logo/fliplogo.webp"

// const access_key = "f65ef0eb-57aa-45ba-91f7-aaf00dfe46ee" // Sinan
const access_key = "bcad8f77-6513-4b70-badc-2db336b225d3" // Sadik

// Captcha key
const site_key = "6LdXo1AqAAAAAPdG0izRyMQhmvDPcRVVPfXIT0CM"
// const secret_key = "6LdAulIqAAAAAJw53TbIBwqKARKE3PfZmQuGL0zC"

function PopupForm({ handleClose }) {
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceOption, setServiceOption] = useState(false)
  const [phone, setPhone] = useState("");
  // const [captchaVerified, setCaptchaVerified] = useState(false);

  // const handleCaptcha = (value) => {
  //   if (value) {
  //     setCaptchaVerified(true);
  //   } else {
  //     setCaptchaVerified(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!phone) {
      setResult("Phone number is required.")
    }

    // if (!captchaVerified) {
    //   setResult("Please complete the CAPTCHA before submitting.");
    //   return;
    // }

    setLoading(true);
    setResult("Sending....");

    const formData = new FormData(event.target);
    formData.append("access_key", access_key);
    formData.append("phone", phone);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();

        // Wait for 3 seconds to show the success message, then redirect and close
        setTimeout(() => {
          // Redirect to the desired URL
          window.location.href = "https://3danimation.rabbitandcarrot.com/?utm_source=Pop-Up+Form&utm_medium=RNC_Campaign&utm_campaign=Submit+Button&utm_id=SubDomain";
          handleClose(); // Close the form or modal
        }, 1500); // 2 seconds delay
      } else {
        console.error("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setResult("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // Stop loading in all cases
    }
  };

  return (
    <AnimatePresence>
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="modal-overlay">
      <motion.div 
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ duration: 0.5 }}
        className={`modal-content ${ serviceOption === "Other" ? "height" : ""}`}>
        <img src={closeButton} alt="" className="closeButton" onClick={() => { handleClose() }}/>
        {/* <div className="left">
            <h1>rabbotandcarrot</h1>
            <img src={fliplogo} alt="" />
            <p>We’re all about creating communications that sparkle and 
              shine for the brands we love. 
              But there’s more to us than just creating pretty pictures.
            </p>
        </div> */}
        <div className="right">
          <div className="contact-col">
            <div className="head">
              <img src={logo} alt="logo"/>
              <h1> <span className="color">Get a Free Consultation</span><br/>Share your details to connect and discuss.</h1>
            </div>
            <form onSubmit={handleSubmit}>
                {/* <label>Your Name</label> */}
                  <input type="text" name="name" placeholder="Enter Your Name" required/>
                  {/* <label>Phone Number</label> */}
                  <PhoneInput
                    inputStyle={{ border: "none", outline: "none" }}
                    containerStyle={{ display: "flex", alignItems: "center" }}
                    inputProps={{ style: { flex: "1", border: "none", fontSize: 14, boxShadow: "none", background: "transparent" } }}
                    buttonStyle={{ border: "none", boxShadow: "none", outline: "none" }}
                    className="phone-input"
                    placeholder="Enter Your Mobile"
                    name="phone"
                    id="phone"
                    country={"ae"}
                    value={phone}
                    onChange={(value) => {setPhone(value)}}
                    required
                  />
                {/* <input type="tel" name="phone" placeholder="Enter Your Mobile" required/> */}
                {/* <label>Email</label> */}
                <input type="email" name="email" placeholder="Enter Your Email" required/>
                <select name="services" onChange={(e) => setServiceOption(e.target.value)} required>
                  <option value="">Services</option>
                  <option value="Explainer Videos">Explainer Videos</option>
                  <option value="Storytelling Testimonial Videos">Storytelling Testimonial Videos</option>
                  <option value="Educational Videos">Educational Videos</option>
                  <option value="Trade Show Videos">Trade Show Videos</option>
                  <option value="Live Action Videos">Live Action Videos</option>
                  <option value="Social Video Ads">Social Video Ads</option>
                  <option value="Video Case Studies">Video Case Studies</option>
                  <option value="Brand Videos">Brand Videos</option>
                  <option value="Video Retainer">Video Retainer</option>
                  <option value="Other">Other</option>
                </select>
                { serviceOption === "Other" && <input type="text" name="Other Services" placeholder="Enter Your Services" required/>}
                {/* <div className="recaptcha-div">
                  <ReCAPTCHA
                      sitekey={site_key} // Replace with your site key
                      onChange={handleCaptcha}
                      className="recaptcha"
                    />
                </div> */}
                <button 
                  className="btn button-touch3"
                  type="submit"
                  disabled={loading}
                  >{loading ? "Submitting..." : "Let's Talk"}
                        <span className="line">
                            <span className="circle"></span>
                        </span>
                </button>
            </form>
            <span className="result">{result}</span>
          </div>
        </div>
      </motion.div>
    </motion.div>
    </AnimatePresence>
  );
}

export default PopupForm;