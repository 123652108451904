import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import LazyLoad from "react-lazyload"
import { motion } from "framer-motion";

import "./CaseStudy.css"


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import rolex from "../../../Assets/CaseStudy/rolex-logo.webp"
import channel from "../../../Assets/CaseStudy/channel-logo.webp"
import mok from "../../../Assets/CaseStudy/mok-logo.webp"

import thumbnail1 from "../../../Assets/CaseStudy/rolex.webp"
import thumbnail2 from "../../../Assets/CaseStudy/channel.webp"
import thumbnail3 from "../../../Assets/CaseStudy/mok.webp"
import { useState } from "react";
import { FaRegCirclePlay } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const CaseStudies = [
    {
        id: 1,
        title: "ROLEX",
        // logo: rolex,
        description: "We developed a stunning 3D showcase for Rolex watches, highlighting their craftsmanship and luxury. The immersive visuals effectively captivated audiences, reinforcing Rolex’s premium brand image. This dynamic presentation elevated the brand’s presence, delivering a truly unforgettable experience.",
        thumbnail: thumbnail1,
        youtube: "https://youtu.be/MUECjw6ZeBg",
    },
    {
        id: 2,
        title: "CHANEL",
        // logo: channel,
        description: "We created an elegant 3D showcase for Chanel lipsticks, emphasizing their luxury and refined design. The immersive visuals engaged audiences, elevating Chanel’s prestigious brand image. This captivating experience highlighted the product’s beauty and sophistication, leaving a lasting impression.",
        thumbnail: thumbnail2,
        youtube: "https://youtu.be/uRVQA65QY1k",
    },
    {
        id: 3,
        title: "MOK",
        // logo: mok,
        description: "We developed a dynamic CGI video showcase for MOK Vape, highlighting its sleek design and cutting-edge features. The visually striking animation captured attention, reinforcing MOK's innovative brand identity and modern appeal. This immersive experience elevated the product's presence in the market.",
        thumbnail: thumbnail3,
        youtube: "https://youtu.be/02TpJ53Jbyg",
    }
]


export default function CaseStudy() {
    const [playingVideo, setPlayingVideo] = useState(null); // To track the currently playing video

    const handleVideoPlaying = (videoUrl) => {
        setPlayingVideo(videoUrl);
    };

    const closeVideo = () => {
        setPlayingVideo(null);
    };

    const textVarient = {
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
      };
    
      const childVariants = {
          initial: { y: 50, opacity: 0 },
          animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
      };

    return (
        <section id="case-studies" className="caseStudy container">
            {/* <div className="heading-div">
                <h1>
                    <hr />Case Studies
                </h1>
            </div> */}
            <h1 className="commonHeading"><span>Case Studies</span></h1>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 1,
                slideShadows: false, 
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
                {CaseStudies.map((ele) => {
                    return (
                        <SwiperSlide key={ele.id}>
                            <div className='flexSB'>
                                <div className='left'>
                                    <div className="youtube-box">
                                        {!playingVideo && (
                                            <div className="overlay">
                                                <FaRegCirclePlay />
                                            </div>
                                        )}
                                        {playingVideo === ele.youtube ? (
                                            <div className="video-player">
                                            <ReactPlayer
                                                url={ele.youtube}
                                                playing={true}
                                                controls={true}
                                                width="100%"
                                                height="100%"
                                            />
                                            <button className="close-btn" onClick={closeVideo}>
                                                <IoClose />
                                            </button>
                                            </div>
                                        ) : (
                                            <img
                                            src={ele.thumbnail}
                                            alt={ele.title}
                                            onClick={() => handleVideoPlaying(ele.youtube)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <motion.div className='right'>
                                    <motion.div className="aboutHeading" initial="initial" whileInView="animate" variants={textVarient}>
                                        <motion.div variants={childVariants} className="top">
                                            {/* <img src={ele.logo} alt="2D Animation Company in Dubai" /> */}
                                            <h2>{ele.title}</h2>
                                        </motion.div>
                                        <motion.p variants={childVariants}>{ele.description}</motion.p>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </SwiperSlide>
                    )
                })}
                <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
        </section>
    )
}