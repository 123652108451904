import React from 'react'
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"
import { FaRightLong, FaLeftLong } from "react-icons/fa6";

import "./Footer.css"

import logo from "../../../Assets//Logo/fliplogo.gif"
import clutch from "../../../Assets/Common/clutch-logo.webp"
import mail_icon from "../../../Assets/Common/mail.svg"
import phone_icon from "../../../Assets/Common/phone.svg"

export default function Footer() {
  const location = useLocation()

  const textVariants = {
    initial: {
      y: 50,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.3
      }
    },
  }

  const childVariants = {
    initial: { y: 10, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };
  return (
    <footer className="footer">
      <hr/>
      <div className="footer-top">
        <div className="row">
          <motion.div className="col rnc-details-col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.img variants={childVariants} src={logo} alt="" className="logo"/>
            <motion.p variants={childVariants} className='logo-details'>
            We’re all about creating communications that sparkle and shine for the brands we love. 
            But there’s more to us than just creating pretty pictures.</motion.p>
            <motion.ul variants = {textVariants} initial="initial" whileInView="animate" className="logo-contact">
              {/* <motion.a variants={childVariants} href="/"><li><img className="mail-icon" src={mail_icon} alt=""/>info@rabbitandcarrot.com<br/></li></motion.a> */}
              {/* <motion.a variants={childVariants} href="tel:+971542226464"><li><img className="phone-icon" src={phone_icon} alt=""/>+971 542226464</li></motion.a> */}
              {/* <motion.a variants={childVariants} href="https://clutch.co/profile/rabbit-carrot"> */}
              <li><div className="clutch-review">
                <div className="left">
                  REVIEWED ON
                  <img src={clutch} alt="" />
                </div>
                <div className="right">
                <div className="rating">
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                  </div>
                  5.0 RATING
                </div>
              </div></li>
              {/* </motion.a> */}
            </motion.ul>
          </motion.div>
          <motion.div className="col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.h3 variants={childVariants}>SERVICES <div className="underline"><span></span></div></motion.h3>
            <motion.ul variants = {textVariants} initial="initial" whileInView="animate">
              {/* <motion.li>Explainer Videos</motion.li> */}
              <motion.li>3D Explainer Videos</motion.li>
              <motion.li>Story Telling Videos</motion.li>
              <motion.li>Educational Videos</motion.li>
              <motion.li>Trade Show Videos</motion.li>
              <motion.li>Live Action Videos</motion.li>
              <motion.li>Social Video Ads</motion.li>
              <motion.li>Video Case Studies</motion.li>
              {/* <motion.li>Video Courses</motion.li> */}
              <motion.li>Brand Videos</motion.li>
              {/* <motion.li>Video Retainer</motion.li> */}
            </motion.ul>
          </motion.div>
          <motion.div className="col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.h3 variants={childVariants}>QUICK LINKS <div className="underline"><span></span></div></motion.h3>
            <motion.ul variants = {textVariants} initial="initial" whileInView="animate">
              <motion.li><a href="#about-contact" className={`${location.hash === "#about-contact" ? "active" : ""}`}>About RNC</a></motion.li>
              <motion.li><a href="#services" className={`${location.hash === "#services" ? "active" : ""}`}>Services</a></motion.li>
              <motion.li><a href="#process" className={`${location.hash === "#process" ? "active" : ""}`}>Process</a></motion.li>
              <motion.li><a href="#portfolio" className={`${location.hash === "#portfolio" ? "active" : ""}`}>Portfolio</a></motion.li>
              <motion.li><a href="#use-cases" className={`${location.hash === "#use-cases" ? "active" : ""}`}>Use Cases</a></motion.li>
              <motion.li><a href="#case-studies" className={`${location.hash === "#case-studies" ? "active" : ""}`}>Case Studies</a></motion.li>
              <motion.li><a href="#testimonials" className={`${location.hash === "#testimonials" ? "active" : ""}`}>Testimonials</a></motion.li>
            </motion.ul>
          </motion.div>
          <motion.div className="col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.h3 variants={childVariants}>VIDEO STYLES<div className="underline"><span></span></div></motion.h3>
            <motion.ul variants = {textVariants} initial="initial" whileInView="animate">
              <motion.li>2D Animation</motion.li>
              <motion.li>3D Animation</motion.li>
              <motion.li>Live Action Video</motion.li>
              <motion.li>Motion Graphics</motion.li>
              <motion.li>White Board Animaton</motion.li>
              <motion.li>Traditional Animation</motion.li>
              <motion.li>Vector Animation</motion.li>
              {/* <motion.li>Virtual Reality (VR) Animation</motion.li>
              <motion.li>Special Effects Animation (VFX)</motion.li> */}
            </motion.ul>
          </motion.div>
          <motion.div className="col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.h3 variants={childVariants}>USE CASES<div className="underline"><span></span></div></motion.h3>
            <motion.ul variants = {textVariants} initial="initial" whileInView="animate">
              <motion.li>Culture Video</motion.li>
              <motion.li>Email Marketing Video</motion.li>
              <motion.li>Explainer Video</motion.li>
              <motion.li>Homepage Video</motion.li>
              <motion.li>Landing Page Video</motion.li>
              <motion.li>Sales Enablement Video</motion.li>
              <motion.li>Social Ad Video</motion.li>
              <motion.li>Thought Leadership Video</motion.li>
            </motion.ul>
          </motion.div>
          {/* <motion.div className="col" variants = {textVariants} initial="initial" whileInView="animate">
            <motion.h3 variants={childVariants}>INDUSTRIES <div className="underline"><span></span></div></motion.h3>
            <ul>
              <li>B2B Video</li>
              <li>B2C Video</li>
              <li>NFP Video</li>
              <li>Technology</li>
              <li>Education</li>
              <li>Health Care</li>
              <li>Travel & Tourism</li>
              <li>Automotive</li>
              <li>Communication</li>
            </ul>
          </motion.div> */}
        </div>
        {/* <img src={logo} alt=""/>
        <p className="details">We’re all about creating communications that sparkle and shine for the brands we love.
        But there’s more to us than just creating pretty pictures.</p> */}
        <div className="chat-on">
          <div className="chat-on-div live-chat"><a href="#about-contact">GET IN TOUCH</a></div>
          <div className="chat-on-div">
            <div className="chat">
            <motion.div
              initial= {{
                opacity: 0
              }}
              animate= {{
                opacity: 1,
                x: -10,
                transition: {
                  duration: 1.5,
                  repeat: Infinity
                }
              }}><FaLeftLong size={"25px"}/>
              </motion.div>
            CHAT ON 
            <motion.div
              initial= {{
                opacity: 0
              }}
              animate= {{
                opacity: 1,
                x: 10,
                transition: {
                  duration: 1.5,
                  repeat: Infinity
                }
              }}><FaRightLong size={"25px"}/>
              </motion.div>
            </div>
          </div>
          <div className="chat-on-div whatsapp"><a href="https://wa.me/+971542226464">WHATSAPP</a></div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyRight">Rabbit And Carrot © 2008 - 2025. All Rights Reserved.</p>
        {/* <p className="policy">Terms of Use | Privacy Policy | Cookie Policy</p> */}
      </div>
    </footer>
  )
}