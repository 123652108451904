import "./ProcessNew.css"

import process1 from "../../../Assets/Process/1.mp4"
import process2 from "../../../Assets/Process/2.mp4"
import process3 from "../../../Assets/Process/3.mp4"
import process4 from "../../../Assets/Process/4.mp4"
import process5 from "../../../Assets/Process/5.mp4"

const process = [
    {
        id: 1,
        title: "Concept Development",
        description: "Start with an idea or story. Decide on what you want to animate and what kind of message or action you want to convey.",
        image: process1
    },
    {
        id: 2,
        title: "Storyboarding",
        description: "A simple storyboard where you are going to sketch out the scenes, actions, and camera angles will be done. This will be the blueprint for your animation.",
        image: process2,
    },
    {
        id: 3,
        title: "Modeling",
        description: "Create models of your characters, objects, and environments using any 3D software. Start with simple shapes and then add details and textures to them.",
        image: process3,
    },
    {
        id: 4,
        title: "Animation",
        description: "The process involves defining movements using key frames and letting the software frames smooth motion for natural results.",
        image: process4,
    },
    {
        id: 5,
        title: "Lighting",
        description: "Add realism to your set with lighting and place virtual cameras to frame up and capture your scenes dynamically.",
        image: process5,
    },
    {
        id: 6,
        title: "Rendering",
        description: "Process your animation into high-quality frames or videos using rendering software. This step transforms your 3D scene into a polished visual output, considering lighting, textures, and effects.",
        image: process3,
    },
    {
        id: 7,
        title: "Audio Integration",
        description: "We amplify your message with professional voice overs in multiple languages that make every word resonate with your audience. ",
        image: process5,
    },
    {
        id: 8,
        title: "Final Touches",
        description: "Add music, sound effects, and edits in post-production software to enhance the final animation.",
        image: process1,
    },
]

export default function ProcessNew() {
    return (
        <section id="process">
            <div className="process-container container1">
                <div className="title">
                    <h2>Our Process</h2>
                </div>
                <div className="process-grid">
                    {process.map((ele, i) => {
                        return (
                            <div className="process-grid-card">
                                <span className="si-no">{i+1}.</span>
                                <div className="process-image">
                                    <video src={ele.image} alt={ele.title} autoPlay loop muted />
                                </div>
                                <div className="process-content">
                                        <h1>{ele.title}</h1>
                                        <p>{ele.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}