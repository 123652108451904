import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Common/Navbar/Navbar';
import Navbar1 from "./Components/Common/PageNotFound/Navbar1";
import Footer from './Components/Common/Footer/Footer';
import Footer1 from "./Components/Common/PageNotFound/Footer1";
// import Thankyou from './Pages/Thankyou';
import PopupForm from './Components/Common/PopupForm/PopupForm';
import PageNotFound from './Components/Common/PageNotFound/PageNotFound';
// import ZohoForm from './Components/Common/PopupForm/ZohoForm';

// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    import('./Components/Common/Scripts/trackingscripts').then(({ initGoogleAnalytics, initClarity, initFacebookPixel }) => {
        initGoogleAnalytics();
        initClarity();
        initFacebookPixel();
    });
  }, []);

  
  const [size, setSize] = useState(50);

  useEffect(() => {
      const handleResize = () => {
          const width = window.innerWidth;
          // setScreenWidth(width);
          if (width < 500) {
            setSize(0);
          } else if (width < 700) {
            setSize(10);
          } else if (width < 1200) {
            setSize(20);
          } else {
            setSize(50); 
          }
      };
      
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
      
      // Initial check in case window width has already changed
      handleResize();
      
      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const location = useLocation();
  const [closePopup, setClosePopup] = useState(false);
  const [formAppear, setFormAppear] = useState(false);

  // Function to handle closing the popup and storing flag in localStorage
  const handleClose = () => {
    setClosePopup(true);
    localStorage.setItem('formAppear', 'true'); 
  };

  // Check localStorage on initial load
  useEffect(() => {
    const formAppearFlag = localStorage.getItem('formAppear');
    if (formAppearFlag === 'true') {
      setFormAppear(false); 
    } else {
      setFormAppear(true); 
    }
  }, []);

  return (
    <Fragment>
      {location.pathname === "/" ? <Navbar/> : <Navbar1 />}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<Home size={size} />} />
        {/* <Route path="/thank-you" element={<Thankyou />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname === "/" ? <Footer /> : <Footer1 />}
      {/* {<ZohoForm/> } */}
      {location.pathname === "/" && !closePopup && formAppear &&
      <PopupForm handleClose={handleClose}/>
      }
    </Fragment>
  );
}

export default App;